
import { ColumnDef } from "@tanstack/react-table"
// import Charts from "src/components/ui/common/Chart/Charts";
import { Switch } from "src/components/ui/switch";
import Datehistory from "./Datehistory";
import ActionBuy from "./ActionBuy";


export type InvoiceListViewProps = {
    name: string,
    consumerNumber: string,
    tradeUnit: number,
    consumedUnit: number,
    buyerPayable: number,
    buyerSaving: number,
    buyerUser:string

  
    buyer: string,
   
   


}




export const columnsInvoiceListViewBuy: ColumnDef<InvoiceListViewProps>[] = [
    {
        accessorKey: "consumerNumber",
        header: "Consumer Number",
        // cell: ({ row: { original } }) => {
        //     return <Datehistory {...original} />
        // }
    },
    {
        accessorKey: "name",
        header: "Name",
        // cell: (({ row }) => {
        //     let typ: string = row.getValue('type');
        //     return <SwitchComp types={typ} />
        // })
    },
    {
        accessorKey: "buyer",
        header: "Platform Id",
  
    },

    {
        accessorKey: "tradeUnit",
        header: "Scheduled Energy on Platform (kWh)",
        cell: ({ row }: any) => {
            let val: number = row.getValue("tradeUnit");
            return <div className="text-center">{val || val === 0 ? val?.toFixed(2) : "-"}</div>;
        },

    },
    {
        accessorKey: "consumedUnit",
        header: "Purchased Energy from Platform (kWh)",
        cell: ({ row }: any) => {
            let val: number = row.getValue("consumedUnit");
            return <div className="text-center">{val || val === 0 ? val?.toFixed(2) : "-"}</div>;
        },
    },
    {
        accessorKey: "buyerPayable",
        header: "Total Payable ( ₹ )",
        cell: ({ row }: any) => {
            let val: number = row.getValue("buyerPayable");
            return <div className="text-center">{val || val === 0 ? val?.toFixed(2) : "-"}</div>;
        },
    },

    {
        accessorKey: "buyerSaving",
        header: "Net Benefit ( ₹ )",
        cell: ({ row }: any) => {
            let val: number = row.getValue("buyerSaving");
            return <div className="text-center">{val || val === 0 ? val?.toFixed(2) : "-"}</div>;
        },
    },

    {
        id: "viewDetails",
        header: "Details",
        cell: ({ row: { original } }) => {
            // let val: number = row.getValue("buyerSaving");
            return <ActionBuy {...original} />;
        },
    },
]

