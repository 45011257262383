import React, { useEffect, useMemo } from "react";
import {
  QueryRequestProvider,
  QueryResponseProvider,
  useQueryResponseData,
  useQueryResponseLoading,
  UserListMainState,
} from "./Context";
import SearchableDropDown from "../../Searchfilter/SearchableDropDown";
type Props = {
  onchange: (val: string) => void;
  userId: string;
  consumerType?:string
};
const Main = ({ onchange, userId,consumerType }: Props) => {
  const isLoading = useQueryResponseLoading();

  const response: any = useQueryResponseData();

  // const data = useMemo(() => response ? response?.data : [], [isLoading, response])
  const { state, updateFilter, updateState } = UserListMainState();
  const data = useMemo(
    () => (isLoading ? [] : response || []),
    [isLoading, response]
  );

  useEffect(()=>{
    updateFilter && updateFilter({
      consumerType:consumerType
    })
  },[consumerType])

  const dropdownchange = (val: string) => {
    onchange && onchange(val);
    // setUserId(val);
  };

console.log(consumerType)
 
  return (
    <div className="w-full">
      <SearchableDropDown
        option={data || []}
        handleChange={dropdownchange}
        value={userId &&userId!==undefined?userId : ""}
        placeholder="Select user"
        // label="User"
      />
    </div>
  );
};
const UserdropdownMain: React.FC<Props> = ({ onchange, userId,consumerType }) => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <Main onchange={onchange} userId={userId} consumerType={consumerType} />
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  );
};
export default UserdropdownMain;
