import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ENUM_STATUS_CODE, stringifyRequestQuery } from "../../helpers";
import { getTimeBlockList } from "src/services/services";
import { updateError, updateErrorCode } from "./errorHadlingSlicer";
import axios, { AxiosError } from "axios";
import dayjs from "dayjs";

interface ITimeBlockData {
  timeBlockData: any[];
  month: string;
  year: string;
  loading: boolean;
  isOldMonth: boolean;
  canEdit: boolean;
  message: string;
}
const months = dayjs(new Date()).format("MM");
const years = dayjs(new Date()).format("YYYY");
const initialState: ITimeBlockData = {
  timeBlockData: [],
  month: months,
  year: years,
  loading: false,
  isOldMonth: true,
  canEdit: true,
  message: "",
};

const transformToQueryString = (data: any) => {
  const filterkeys: string[] = ["month", "year"];
  const f_keys: string[] = [""];
  const q_keys: string[] = [""];

  let filters: any = {};
  let _f: any = {};
  let _q: any = {};

  data &&
    Object.entries(data).map(([key, value]) => {
      if (filterkeys.length > 0 && filterkeys.includes(key)) {
        filters[key] = value;
      } else if (f_keys.length > 0 && f_keys.includes(key)) {
        _f[key] = value;
      } else if (q_keys.length > 0 && q_keys.includes(key)) {
        _q[key] = value;
      }
    });

  return stringifyRequestQuery({
    filter: filters,
    f: _f,
    q: _q,
    items_per_page: data.items_per_page,
    page: data.page,
  });
};

export const timeblockList: any = createAsyncThunk(
  "list/timeblockList",
  async (data: any, thunkApi: any) => {
    try {
      const getFilterState = thunkApi.getState().timeblock;

      let newFilterState = {
        ...getFilterState,
      };

      let filterState = {
        ...newFilterState,
      };
      const queryString: string = transformToQueryString(filterState);

      const response: any = await getTimeBlockList(queryString);

      if (response.code === ENUM_STATUS_CODE?.SUCCESS && response.data) {
        return response?.data; // Resolve the Promise with the successful response
      } else {
        const errorMessage = response.message;

        thunkApi.dispatch(updateError(errorMessage));
        thunkApi.dispatch(updateErrorCode(response?.code));
        return thunkApi.rejectWithValue(errorMessage);
      }
    } catch (_error) {
      const error = _error as Error | AxiosError;

      if (axios.isAxiosError(error)) {
        thunkApi.dispatch(updateError(error.response?.data.message));
        thunkApi.dispatch(updateErrorCode(error.response?.data?.code));
        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        thunkApi.dispatch(updateError(error.message));
      }

      thunkApi.dispatch(updateError(error.message));

      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const timeblockSlicer = createSlice({
  name: "timebock/timeblockSlicer",
  initialState,
  reducers: {
    updateTimeBlockData(state, action: PayloadAction<any>) {
      const a = [...state.timeBlockData, action.payload];
      state.timeBlockData = a;
    },
    removeTimeBlockData(state, action: PayloadAction<any>) {
      const a = action.payload;
      state.timeBlockData = a;
    },
    updateTimeBlockMonthYear(state, action: PayloadAction<any>) {
      state.month = dayjs(action.payload).format("MM");
      state.year = dayjs(action.payload).format("YYYY");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(timeblockList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.timeBlockData = Array.isArray(payload.data)&&payload.data?.length>0?payload.data.map((cur: any) => {
        return {
          endTime: cur?.endTime,
          interval: cur?.interval,
          isActive: cur?.isActive,
          month: cur?.month,
          year: cur?.year,
          startTime: cur?.startTime,
          timeslot: cur?.timeslot,
        };
      }):[];
      state.isOldMonth = payload.editability?.isOldMonth;
      state.canEdit = payload.editability?.canEdit;
      state.message = payload.editability?.message;
    });
    builder.addCase(timeblockList.pending, (state) => {
      state.loading = true;
      state.timeBlockData = [];
    });
    builder.addCase(timeblockList.rejected, (state, { payload }) => {
      state.loading = false;
      state.timeBlockData = [];
    });
  },
});

export const {
  updateTimeBlockData,
  updateTimeBlockMonthYear,
  removeTimeBlockData,
} = timeblockSlicer.actions;

export const timeblockReducerState = (state: RootState) => state.timeblock;
export default timeblockSlicer.reducer;
