import React, { useMemo, useState } from "react";
import { QueryRequestProvider, QueryResponseProvider } from "./Context";
import Filters, { FilterObjProps } from "./Component/Filters";
import ListViewTable from "./Component/ListViewTable";
import { Card } from "../../../../components//ui/card";
import { ShieldAlert } from "lucide-react";

type Props = {};

const Main = (props: Props) => {
  const [filterObj, setFilterObje] = useState<FilterObjProps>({
    startDate: "",
    endDate: "",
    side: "",
    userID:""
  });

  const isFilterAvl = useMemo(() => {
    const { userID, ...a } = filterObj;
    return Object.values(a)?.every(
      (cur: any) => cur !== "" && cur !== undefined
    );
  }, [filterObj]);
  return (
    <>
      <Card className="w-full bg-white p-2 h-full overflow-scroll no-scrollbar">
        <div className="w-full flex justify-between items-center">
        <h1 className=" text-lg text-textLightBlack font-bold px-4 mt-2 whitespace-nowrap">
            Invoice List
          </h1>
        <Filters setFilterObje={setFilterObje} />
        </div>
       

        {isFilterAvl ? (
          <ListViewTable filterObj={filterObj} />
        ) : (
          <div className="w-full h-full flex justify-center items-center -mt-6 px-4">
            <div className=" border border-purple-600 rounded-md flex justify-center items-center space-x-2 text-purple-600 p-2">
              <ShieldAlert className="w-4 h-4 " />
              <span className="font-bold text-sm">
                Please select the appropriate filter...
              </span>
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

const ListViewMain = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <Main />
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  );
};

export default ListViewMain;
