import { Link } from "react-router-dom";
import { Badge } from "../../../../../../../../components/ui/badge";
import React from "react";
import { InvoiceListView } from "../../..";
import { InvoiceListViewProps } from "./columnsInvoiceListViewBuy";

type Props = {};

const ActionBuy = ({ buyerUser }: InvoiceListViewProps) => {
  const { state, updateState } = InvoiceListView();

  // console.log(state);

  return (
    <>
      <Link
        to={`/invoice/details?start_date=${state?.filter?.startDate}&end_date=${state?.filter?.endDate}&purchase_type=${state?.filter?.side}&user=${buyerUser}`}
      >
        <Badge
          className="w-32 flex cursor-pointer justify-center space-x-3 items-center text-text_primaryDarkBlue bg-white hover:bg-white border border-border_primaryDarkBlue h-8 text-xs font-medium "
          // onClick={actionClick}
        >
          View Details
        </Badge>
      </Link>
    </>
  );
};

export default ActionBuy;
