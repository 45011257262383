import React, { useEffect, useState } from "react";
import { Tabs, TabsList, TabsTrigger } from "src/components/ui/tabs";
import { ENUM_CONSUMER_TYPE, ENUM_PURCHASE_TYPE } from "src/helpers";
// import { useAuth } from "src/modules/Auth/Core";
import { BillPaymentMainState } from "../../../Context/index";
import { useQueryResponseLoading } from "../../../Context/index";
interface PurchasetypeFilterProps {
  setPurchaseType: (val: string) => void;
  purchase_type?: string;
  consumerType?: string;
}
const PurchasetypeFilter = ({
  setPurchaseType,
  purchase_type,
  consumerType,
}: PurchasetypeFilterProps) => {
  // const {auth}=useAuth()

  const {
    state: MainState,
    updateState,
    updateFilter,
  } = BillPaymentMainState();
  const isLoading = useQueryResponseLoading();

  const [activeTab, setActiveTab] = useState<string>(
    purchase_type || ENUM_PURCHASE_TYPE?.BUY
  );

  // Sync activeTab with purchase_type when it changes
  useEffect(() => {
    if (purchase_type) {
      setActiveTab(purchase_type);
    }
  }, [purchase_type]);

  const onTabChange = (value: string) => {
    setActiveTab(value); // Update local state for active tab
    if (setPurchaseType) {
      setPurchaseType(value); // Call parent handler
    }
  };

  return (
    <div className="w-full">
      <Tabs
        // defaultValue={purchase_type && purchase_type===ENUM_PURCHASE_TYPE?.SELL?ENUM_PURCHASE_TYPE?.SELL:ENUM_PURCHASE_TYPE?.BUY}
        //  defaultValue={purchase_type}
        value={activeTab}
        className=" bg-[#706D6D] rounded-full p-0  "
        onValueChange={onTabChange}
      >
        <TabsList className="grid  grid-cols-2 bg-white border border-border_primaryDarkBlue text-textLightBlack ">
          <TabsTrigger
            value={ENUM_PURCHASE_TYPE?.BUY}
            className=" px-4 data-[state=active]:bg-primaryDarkBlue data-[state=active]:text-white "
          >
            Consumer
          </TabsTrigger>
          <TabsTrigger
            value={ENUM_PURCHASE_TYPE?.SELL}
            className="px-4 data-[state=active]:bg-primaryDarkBlue data-[state=active]:text-white "
            disabled={
              consumerType !== "" &&
              consumerType === ENUM_CONSUMER_TYPE?.CONSUMER
            }
          >
            Prosumer
          </TabsTrigger>
        </TabsList>
      </Tabs>
    </div>
  );
};

export default PurchasetypeFilter;
