import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LayoutMain from "src/Modules/Layout/LayoutMain";
import { WithChildren } from "src/helpers";
import TopBarProgress from "react-topbar-progress-indicator";
import ListViewMain from "src/Modules/Bill&Payment/Component/ListView/ListViewMain";
const DeveloperRotes = () => {
  const DashBoardMain = lazy(
    () => import("src/Modules/Dashboard/DashboardMain/DashBoardMain")
  );
  const BlockchainpageMain = lazy(
    () => import("src/Modules/Dashboard/BlockchainDetails/BlockchainpageMain")
  );
  const ViewMap = lazy(
    () =>
      import(
        "src/Modules/Dashboard/DashboardMain/Component/MapSub/Component/ViewMap"
      )
  );
  const UserListMain = lazy(
    () => import("src/Modules/UserOnboarding/User/UserListMain")
  );
  const OnboardingActionMain = lazy(
    () =>
      import(
        "src/Modules/UserOnboarding/OnboardRegistration/Component/Actions/OnboardingActionMain"
      )
  );
  const VerificationReportMain = lazy(
    () => import("src/Modules/VerificationReport/VerificationReportMain")
  );
  const TarifChargesMain = lazy(
    () => import("src/Modules/ConfigrationPanel/Tarif&Charges/TarifChargesMain")
  );
  const BillMain = lazy(() => import("src/Modules/Bill&Payment/BillMain"));
  const TarrifMain = lazy(() => import("src/Modules/Tarrif/TarrifMain"));
  const AllTransactionMain = lazy(
    () => import("src/Modules/Transaction/AllTransaction/AllTransactionMain")
  );
  const FailTransactionMain = lazy(
    () => import("src/Modules/Transaction/FailTransaction/FailTransactionMain")
  );
  const PPAContractsMain = lazy(
    () => import("../Modules/Transaction/AllTransaction/PPAContractsMain")
  );
  const MeterReadingMain = lazy(
    () => import("src/Modules/MeterReading/MeterReadingMain")
  );
  const MetabaseReportRoutePage = lazy(
    () => import("src/Modules/MetabaseReport/MetabaseReportRoutePage")
  );
  const GrossCaptureMain = lazy(
    () => import("src/Modules/GrossCaptureForm/GrossCaptureMain")
  );

  const BannerMain = lazy(() => import("../Modules/Banner/BannerMain"));

  const Createmetabase = lazy(
    () =>
      import(
        "../Modules/MetabaseReport/Component/CreateMetabaseForm/CreateMetabaseFormMain"
      )
  );

  const TarrifHistoryMain = lazy(
    () =>
      import("../Modules/ConfigrationPanel/DiscomeHistory/TarrifHistoryMain")
  );
  const GateClosureMain = lazy(
    () => import("../Modules/Gateclosure/GateClosureMain")
  );

  const TimeBlockMain = lazy(
    () => import("../Modules/TimeBlock/TimeBlockMain")
  );
  return (
    <Routes>
      <Route element={<LayoutMain />}>
        <Route path="/*" element={<Navigate to="/dashboard" />} />
        <Route
          path="/dashboard"
          element={
            <SuspensedView>
              <DashBoardMain />
            </SuspensedView>
          }
        />
        <Route
          path="/dashboard-blockchaindetails"
          element={
            <SuspensedView>
              <BlockchainpageMain />
            </SuspensedView>
          }
        />
        <Route
          path="/dashboard-map"
          element={
            <SuspensedView>
              <ViewMap />
            </SuspensedView>
          }
        />
        <Route
          path="/user-management"
          element={
            <SuspensedView>
              <UserListMain />
            </SuspensedView>
          }
        />
        {/* <Route path='/userList/onboarding' element={ <SuspensedView><OnboardMain/>}/></SuspensedView> */}
        <Route
          path="/user-management/onboarding-action"
          element={
            <SuspensedView>
              <OnboardingActionMain />
            </SuspensedView>
          }
        />
        <Route
          path="/reports"
          element={
            <SuspensedView>
              <VerificationReportMain />
            </SuspensedView>
          }
        />
        <Route
          path="/tariff-configuration-panel"
          element={
            <SuspensedView>
              <TarifChargesMain />
            </SuspensedView>
          }
        />
        <Route
          path="/invoice"
          element={
            <SuspensedView>
              <ListViewMain />
            </SuspensedView>
          }
        />
        <Route
          path="/invoice/details"
          element={
            <SuspensedView>
              <BillMain />
            </SuspensedView>
          }
        />
        <Route
          path="/tariff-configuration-panel/customer-tariff"
          element={
            <SuspensedView>
              <TarrifMain />
            </SuspensedView>
          }
        />
        <Route
          path="/transaction"
          element={
            <SuspensedView>
              <AllTransactionMain />
            </SuspensedView>
          }
        />
        <Route
          path="/transaction/fail"
          element={
            <SuspensedView>
              <FailTransactionMain />
            </SuspensedView>
          }
        />
        <Route
          path="/ppa-contract"
          element={
            <SuspensedView>
              <PPAContractsMain />
            </SuspensedView>
          }
        />
        <Route
          path="/meter-reading"
          element={
            <SuspensedView>
              <MeterReadingMain />
            </SuspensedView>
          }
        />
        <Route
          path="reports/analytical-report/*"
          element={
            <SuspensedView>
              <MetabaseReportRoutePage />
            </SuspensedView>
          }
        />

        <Route
          path="/gross-energy"
          element={
            <SuspensedView>
              <GrossCaptureMain />
            </SuspensedView>
          }
        />

        <Route
          path="/banner"
          element={
            <SuspensedView>
              <BannerMain />
            </SuspensedView>
          }
        />
     <Route
          path="/timeblock"
          element={
            <SuspensedView>
              <TimeBlockMain/>
            </SuspensedView>
          }
        />
        <Route
          path="/developers"
          element={
            <SuspensedView>
              <Createmetabase />
            </SuspensedView>
          }
        />

        <Route
          path="configuration-tarif/tarrif/tarrif-history"
          element={
            <SuspensedView>
              <TarrifHistoryMain />
            </SuspensedView>
          }
        />
        <Route
          path="/gate-closure"
          element={
            <SuspensedView>
              <GateClosureMain />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }: any) => {
  // const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      "0": "#831843",
    },
    barThickness: 2,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default DeveloperRotes;
