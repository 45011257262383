
import { ColumnDef } from "@tanstack/react-table"
// import Charts from "src/components/ui/common/Chart/Charts";
import { Switch } from "src/components/ui/switch";
import Datehistory from "./Datehistory";
import ActionBuy from "./ActionBuy";
import ActionSell from "./ActionSell";


export type InvoiceListSellViewProps = {
    name: string,
    consumerNumber: string,
    buyer: string,
    buyerUser: string,
    tradeUnit: number,
    generatedunit: number,
    sellerReceivable: number,
    sellerEarning: number
}




export const columnsInvoiceListViewSell: ColumnDef<InvoiceListSellViewProps>[] = [
    {
        accessorKey: "consumerNumber",
        header: "Consumer Number",
        // cell: ({ row: { original } }) => {
        //     return <Datehistory {...original} />
        // }
    },
    {
        accessorKey: "name",
        header: "Name",
        // cell: (({ row }) => {
        //     let typ: string = row.getValue('type');
        //     return <SwitchComp types={typ} />
        // })
    },
    {
        accessorKey: "buyer",
        header: "Platform Id",
  
    },
    {
        accessorKey: "tradeUnit",
        header: "Scheduled Energy on P2P (kWh)",
        cell: ({ row }: any) => {
            let val: number = row.getValue("tradeUnit");
            return <div className="text-center">{val || val === 0 ? val?.toFixed(2) : "-"}</div>;
        },
    },
    {
        accessorKey: "generatedunit",
        header: "Sold Energy to P2P (kWh)",
        cell: ({ row }: any) => {
            let val: number = row.getValue("generatedunit");
            return <div className="text-center">{val || val === 0 ? val?.toFixed(2) : "-"}</div>;
        },
    },
    {
        accessorKey: "sellerReceivable",
        header: "Total Receivable ( ₹ )",
        cell: ({ row }: any) => {
            let val: number = row.getValue("sellerReceivable");
            return <div className="text-center">{val || val === 0 ? val?.toFixed(2) : "-"}</div>;
        },
    },

    {
        accessorKey: "sellerEarning",
        header: "Net Benefit ( ₹ )",
        cell: ({ row }: any) => {
            let val: number = row.getValue("sellerEarning");
            return <div className="text-center">{val || val === 0 ? val?.toFixed(2) : "-"}</div>;
        },
    },
    {
        id: "viewDetails",
        header: "Details",
        cell: ({ row: { original } }) => {
            // let val: number = row.getValue("buyerSaving");
            return <ActionSell {...original} />;
        },
    },
]

